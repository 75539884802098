@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-purple: #453774;
  --main-gray: #f2f2f2;
  --main-green: #4d6a54;
  --highlight-gray: #878999;
}

html {
  color-scheme: light !important;
}

body,
#__next {
  /* min-height: 100vh; */
}

* {
  @apply text-black;
  /* @apply text-gray-dark; */
}

:focus-visible {
  @apply outline outline-2 outline-primary;
}
.purple {
  color: var(--main-purple);
}

.purple-bg {
  background-color: var(--main-purple);
}

.navlink {
  @apply w-min lg:w-auto grid justify-items-center;
  font-weight: bold;
}

/* @media (min-width: 640px) { */
/*   .active-link { */
/*     border-bottom: 3px solid #453774; */
/*   } */
/* } */
/*  */
/* @media (max-width: 640px) { */
/*   .active-link { */
/*     border-left: 3px solid #453774; */
/*   } */
/* } */
/*  */
@font-face {
  font-family: 'Qanelas';
  src: url('../public/fonts/qanelas/QanelasExtraBold.otf') format('opentype');
}

.font-qanelas-extrabold {
  font-family: 'Qanelas';
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'Roboto';
}

.header {
  background-color: white;
}

.header-container {
  padding: 0 150px;
}

.spacer {
  padding-top: 75px;
}

.three-coll-center {
  margin-left: 25px;
}

.card {
  @apply border-t-0 rounded-t-lg pt-1 my-4;
  min-width: 72px;
  /* background-color: var(--main-purple); */
}

.card-purple {
  @apply border-t-0 rounded-t-lg pt-1 my-4;
  background-color: var(--main-purple);
}

.card-transparent {
  @apply border-t-0 rounded-t-lg pt-1 my-4;
}

.card-green {
  @apply border-t-0 rounded-t-lg pt-1 my-4;
  background-color: var(--main-green);
}

.card-gray {
  @apply border-t-0 rounded-t-lg pt-1 my-4 bg-gray-mid;
}

.card-content {
  @apply p-4 flex-auto text-gray-mid w-full;
}

.card-content-no-bg {
  @apply p-4 flex-auto text-gray-mid w-full;
}

.card-content-disabled {
  @apply p-4 flex-auto bg-gray-light w-full opacity-40;
}

.card-with-header-content {
  @apply p-4 pt-1 flex-auto bg-white text-gray-mid w-full;
}

.four-column {
  @apply grid grid-cols-1 md:grid-cols-4 gap-2;
}

.star-following,
.hash-following {
  @apply text-gray-mid;
}

.star-following::before {
  content: '*';
  padding: 0 5px;
  @apply text-3xl mt-1 align-middle;
  line-height: 0;
  position: relative;
  top: 3px;
}

.hash-following::before {
  content: '#';
  padding: 0 5px;
  @apply text-xl align-middle;
  line-height: 0;
}

/* @media (max-width: 600px) { */
/*   .grid { */
/*     width: 100%; */
/*     flex-direction: column; */
/*   } */
/* } */

.profile h1 {
  @apply text-5xl;
}

.profile h2 {
  @apply text-xl;
}

.profile-card {
  /* @apply px-3 grid grid-cols-3 py-5 flex flex-grow; */
  background: linear-gradient(180deg, var(--main-purple) 180px, white 180px);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range']::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    cursor: ew-resize;

    border-radius: 50%;
  }
}

#loading {
  animation-name: ckw;
  animation-duration: 1s;
}
@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mentions-input__highlighter,
.mentions-input-without-border__highlighter {
  color: #42337a !important;
}

.mentions-input__control {
  @apply p-3.5 w-full text-base rounded focus:outline-none text-gray-dark box-border outline-none;
}

.mentions-input__input {
  @apply p-3.5 w-full text-base rounded border focus:outline-none border-dark-border text-gray-dark bg-background box-border outline-none;
}

.mentions-input__input:focus {
  @apply focus:border-primary-dark ring-1 ring-primary-dark;
}

.mentions-input__control:focus {
  @apply focus:border-primary-dark ring-1 ring-primary-dark;
}

.mentions-input-without-border__control,
.mentions-input-without-border__input {
  @apply py-2 px-4 w-full text-base rounded focus:outline-none text-gray-dark;
}

.mentions-input__suggestions__list,
.mentions-input-without-border__suggestions__list {
  position: absolute !important;
  top: 14px !important;
  left: 5px !important;
  z-index: 1000 !important;
  background-color: white;
  min-width: 400px;
  max-height: 300px;
  box-shadow: 0 8px 20px -8px rgba(210, 210, 210, 0.5),
    inset 0 -15px 8px -10px rgba(210, 210, 210, 0.5);
  overflow-y: scroll;
}

.mentions-input__suggestions__item,
.mentions-input-without-border__suggestions__item {
  padding: 4px 6px;
  font-size: 14px;
}

.mentions-input__suggestions__item:hover,
.mentions-input-without-border__suggestions__item:hover {
  color: white !important;
  @apply bg-gray-light;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}
.slick-dots button {
  border-radius: 100% !important;
  background-color: white !important;
}
.slick-prev {
  left: 10px !important;
}
.slick-next {
  right: 10px !important;
}
label.file-input__label input[type='file'] {
  position: absolute;
  top: -1000px;
}
.file-input__label {
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
}

@media (max-height: 800px) {
  .new_referral_step_controller {
    min-height: 100vh;
    height: auto;
  }
}

@import '@radix-ui/colors/black-alpha.css';
.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: #F1F1F1;
  border-radius: 99999px;
  width: 100%;
  height: 25px;
  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}
.ProgressIndicator {
  background: rgb(116,135,197);
  background: linear-gradient(90deg, rgba(116,135,197,1) 0%, rgba(83,93,151,1) 49%, rgba(40,36,87,1) 100%); 
  /* border-radius: 25px; */
  /* width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1); */
}

.light-skyblue{
  background-color: #7a8fce;
}

.dark-skyblue{
  background-color: #3d3979;
}