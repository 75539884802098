.layout-container {
  @apply container mx-auto;
}

.spacer {
  padding-top: 30px;
}

.box {
  @apply border-t-0 rounded-t-lg pt-2 mb-3;
  background-color: var(--main-purple);
}

.box-content {
  background-color: white;
}

.four-column {
  @apply grid grid-cols-1 md:grid-cols-4 gap-2;
}
